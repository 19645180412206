<template>
    <aside class="sidebar-container">
        <router-link :to="{name: 'games-index'}" class="nav-item"
                     v-if="$store.getters.hasPermission('read games')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'gamepad']"/>
            </div>
            <p>{{$t('nav.games')}}</p>
        </router-link>

        <router-link :to="{name: 'logs-index'}" class="nav-item"
                     v-if="$store.getters.hasPermission('read logEntries')">
          <div class="icon-container">
            <font-awesome-icon :icon="['fal', 'file-alt']"/>
          </div>
          <p>{{$t('nav.logs')}}</p>
        </router-link>

      <router-link :to="{name: 'players-index'}" class="nav-item"
                   v-if="$store.getters.hasPermission('read players')">
        <div class="icon-container">
          <font-awesome-icon :icon="['fal', 'users']"/>
        </div>
        <p>{{$t('nav.players')}}</p>
      </router-link>

        <router-link :to="{name: 'roles-index'}" class="nav-item"
                     v-if="$store.getters.hasPermission('read roles')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'key']"/>
            </div>
            <p>{{$t('nav.roles_permissions')}}</p>
        </router-link>

        <router-link :to="{name: 'users-index'}" class="nav-item"
                     v-if="$store.getters.hasPermission('read users')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'user-friends']"/>
            </div>
            <p>{{$t('nav.users')}}</p>
        </router-link>
    </aside>
</template>

<script>
    export default {
        name: "Sidebar"
    }
</script>

<style lang="scss" scoped>
.sidebar-container {
  @apply h-full flex flex-col bg-white overflow-y-auto hidden py-4 justify-start;
  box-shadow: 5px 0px 10px #0000001A;
  width: 140px;
  grid-area: sidebar;

  @screen md {
    @apply flex;
  }

  .nav-item {
    @apply flex flex-col items-center px-1 mb-4;

    @screen 3xl {
      @apply mb-6;
    }

    .icon-container {
      @apply p-2 rounded-lg border-2 border-grey-light flex flex-col items-center justify-center;
      height: 40px;
      width: 40px;

      svg {
        @apply text-primary w-auto;
        height: 18px;
      }
    }

    p {
      @apply text-xs font-bold mt-2 text-center text-grey;

      @screen 3xl {
        @apply text-sm;
      }
    }

    &:hover, &:focus {
      .icon-container {
        @apply border-primary;
      }
    }

    &.router-link-active {
      .icon-container {
        @apply bg-primary border-primary;

        svg {
          @apply text-white;
        }
      }
    }
  }
}
</style>