<template>
    <div class="logs-update-modal">
        <ModalContainer :title="$t('logs.edit_log')" identifier="logs-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputSelect v-model="$v.log.player.$model" identifier="player" :label="$t('logs.name')"
                                     :options="player_options"
                                     :disabled="is_saving || is_loading_players" :has-error="$v.log.player.$error"
                                     track-by="id"
                                     :multiple="false" :display-custom-label="(row) => `${row.attributes.name}`">
                        <template v-slot:errors>
                            <p v-if="!$v.log.player.required">
                                {{ $t('validation.x_are_required', {x: $t('logs.player')}) }}
                            </p>
                        </template>
                    </FormInputSelect>
                    <FormInputSelect v-model="$v.log.game.$model" identifier="game" :label="$t('logs.game')"
                                     :options="game_options" :disabled="is_saving || is_loading_games" :has-error="$v.log.game.$error"
                                     track-by="id" :display-custom-label="(row) => `${row.attributes.name}`"
                                     :multiple="false">
                        <template v-slot:errors>
                            <p v-if="!$v.log.game.required">
                                {{ $t('validation.x_are_required', {x: $t('logs.game')}) }}
                            </p>
                        </template>
                    </FormInputSelect>
                    <FormInputText v-model="$v.log.score.$model" identifier="score" :label="$t('logs.score')"
                                   :disabled="is_saving" :has-error="$v.log.score.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.log.score.required">
                                {{ $t('validation.x_is_required', {x: $t('logs.score')}) }}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.log.health.$model" identifier="health" :label="$t('logs.health')"
                                   :disabled="is_saving" :has-error="$v.log.health.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.log.health.required">
                                {{ $t('validation.x_is_required', {x: $t('logs.health')}) }}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>
                <FormInputText v-model="$v.log.message.$model" identifier="message" :label="$t('logs.message')"
                               :disabled="is_saving" :has-error="$v.log.message.$error" :useTextarea="true"
                               :largeTextarea="true">
                    <template v-slot:errors>
                        <p v-if="!$v.log.message.required">
                            {{ $t('validation.x_is_required', {x: $t('logs.message')}) }}
                        </p>
                    </template>
                </FormInputText>
                <FormInputText v-model="$v.log.data.$model" identifier="data" :label="$t('logs.data')"
                               :disabled="is_saving" :has-error="$v.log.data.$error" :useTextarea="true"
                               :largeTextarea="true">
                    <template v-slot:errors>
                        <p v-if="!$v.log.log.required">
                            {{ $t('validation.x_is_required', {x: $t('logs.data')}) }}
                        </p>
                    </template>
                </FormInputText>
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}" :onclick="onInput">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, game} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import _ from "lodash";

export default {
    name: "LogsUpdateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    props: {
        log_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            log: {
                player: null,
                game: null,
                score: null,
                health: null,
                message: null,
                data: null,
            },
            player_options: [],
            game_options: [],
            is_loading_players: false,
            is_loading_games: false,
            is_saving: false,
        }
    },
    validations: {
        log: {
            player: {required},
            game: {required},
            score: {required},
            health: {required},
            message: {required},
            data: {required},
        }
    },
    methods: {
        onInput() {

        },
        close(status) {
            this.$modal.hide('logs-update-modal', status);
        },
        save() {
            this.$v.log.$touch();
            if (this.$v.log.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            this.$axios.patch(`logEntries/${this.log_id}`, {
                player_id: this.log.player.id,
                game_id: this.log.game.id,
                score: this.log.score,
                health: this.log.health,
                message: this.log.message,
                log: this.log.data,
            }).then(({data}) => {
                this.$notify({
                    text: this.$t('logs.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('logs.error_update')),
                    type: 'error',
                });
            });
        },
        async getPlayers() {
            this.is_loading_players = true;

            await this.$axios.get(`players/list`).then(({data}) => {
                this.player_options = data.data;
                this.is_loading_players = false;
            }).catch(e => {
                this.is_loading_players = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('players.error_retrieve')),
                    type: 'error',
                });
            });
        },
        async getGames() {
            this.is_loading_games = true;

            await this.$axios.get(`games/list`).then(({data}) => {
                this.game_options = data.data;
                this.is_loading_games = false;
            }).catch(e => {
                this.is_loading_games = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('games.error_retrieve')),
                    type: 'error',
                });
            });
        },
        getLog() {
            this.is_loading_log = true;

            this.$axios.get(`logEntries/${this.log_id}`).then(({data}) => {
                this.log.player = _.find(this.player_options, {id: data.data.relationships.player.data.id});
                this.log.game = _.find(this.game_options, {id: data.data.relationships.game.data.id});
                this.log.score = data.data.attributes.score;
                this.log.health = data.data.attributes.health;
                this.log.message = data.data.attributes.message;
                this.log.data = data.data.attributes.log;

                this.is_loading_log = false;
            })
                .catch(e => {
                    this.is_loading_log = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('logs.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted() {
        await Promise.all([this.getPlayers(), this.getGames()]);
        this.getLog();
    }
}
</script>
